import { AxiosError } from 'axios';
import config from 'config/config';
import { createEffect } from 'effector';
import { i18n } from 'next-i18next';
import { Router } from 'next/router';
import api from 'services/api';
import { IBaseModalRequestParams, IBaseRequestDataParams } from 'shared/api/types';
import { IPaginatedPersonList, IReferencePerson } from './types';

interface PersonUpdate {
  id: number;
  data: { company_id: string; parent_id: number | null; title: string; type_id?: number };
}

const route = `${config.COMMON_API_URL}/admin/v1/persons`;

export const referencePersonsSources = {
  root: route,
  detail: (id: number) => `${route}/${id}`,
};

export const fetchPersonsFx = createEffect(
  async (query: Router['query'] = {}): Promise<IPaginatedPersonList> => {
    const { location, ...rest } = query;
    const response = await api.get(`${route}`, {
      params: {
        ...rest,
        ...(query && { language_code: i18n?.language }),
        ...(location && { location_id_in: location }),
        language_code: i18n?.language,
      },
    });

    return response.data;
  },
);

export const fetchBasicPersonFx = createEffect(async (parent_id: number): Promise<IReferencePerson> => {
  const response = await api.get(`${config.COMMON_API_URL}/admin/v1/persons/${parent_id}`);

  return response.data;
});

export const createPersonFx = createEffect(
  async ({ type_id, parent_id, title }: { type_id: number; parent_id?: number; title: string }) => {
    const response = await api.post(`${config.COMMON_API_URL}/admin/v1/persons`, {
      parent_id,
      type_id,
      title,
    });

    return response.data;
  },
);

export const fetchPersonFx = createEffect(
  async ({ id }: IBaseModalRequestParams): Promise<IReferencePerson> => {
    const response = await api.get(`${route}/${id}`);

    return response.data;
  },
);

export const updatePersonFx = createEffect<
  PersonUpdate,
  { id: number; parent_id: number | null; title: string; type_id: number | undefined },
  AxiosError
>(async ({ id, data }: PersonUpdate) => {
  const response = await api.patch(`${config.COMMON_API_URL}/admin/v1/persons/${id}`, data);
  return response.data;
});

export const deletePersonFx = createEffect(async ({ id }: IBaseRequestDataParams) => {
  const response = await api.delete(`${config.COMMON_API_URL}/admin/v1/persons/${id}`);

  return response;
});
