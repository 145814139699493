import { basicEventSources } from 'entities/events';
import compact from 'lodash/compact';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import DATE_FORMATS from '../constants/DATE_FORMATS';
import SEAT_SELECTOR_TYPES from '../constants/SEAT_SELECTOR_TYPES';
import api from '../services/api';
import { getAdaptedFilterEvent } from './formatters/event';
import { getDataForMultiSelect } from './loadFiltersData';

export const getEventPreview = ({ title, date, time }) => {
  const dateStart = moment(date, DATE_FORMATS.DATE).format(DATE_FORMATS.MONTH_REVIEW);
  const timeStart = moment(time, DATE_FORMATS.TIME).format(DATE_FORMATS.TIME_PREVIEW);

  return `${title} (${dateStart} ${timeStart})`;
};

export const getEventOptionPreview = (data) => {
  const dateStart = moment(data.date_start, DATE_FORMATS.DATE).format(DATE_FORMATS.MONTH_REVIEW);
  const timeStart = moment(data.time_start, DATE_FORMATS.TIME).format(DATE_FORMATS.TIME_PREVIEW);

  return { value: data.id, name: `${data.title} (${dateStart} ${timeStart})`, data };
};

export const hasNumericTickets = (announcement) => announcement.ticketsType === SEAT_SELECTOR_TYPES.NUMBERED;

// TODO: delete
export const getEventsDataForMultiSelect = (parentIds: any[], childIds: string) =>
  Promise.all(parentIds.map((item) => api.get(basicEventSources.detail(item)).catch((e) => e))).then(
    (response) => {
      const eventsData = response
        .filter((item: any) => !!item.data)
        .map((item: any) => ({
          ...getAdaptedFilterEvent(item.data, true),
          filter: { isEventFilter: true, value: { date_id: childIds } },
        }));
      const events = compact(eventsData.map((item) => item));

      const eventsForMultiSelect = getDataForMultiSelect(eventsData);

      return {
        events,
        eventsData,
        eventsForMultiSelect,
      };
    },
  );

export const isPasteDate = (date) => {
  if (moment(date).isBefore(moment().subtract(1, 'minutes'))) return true;
  return false;
};

export const sortChildrenEvents = (events: any[], dateFinish: boolean) => {
  const compareFunction = (item) => {
    let dateStart;
    if (dateFinish) {
      dateStart = !item.date_time_finish ? `${item.date_finish} ${item.time_finish}` : item.date_time_finish;
    } else {
      dateStart = !item.date_time_start ? `${item.date_start} ${item.time_start}` : item.date_time_start;
    }
    const isOutdated = isPasteDate(dateStart);
    return !isOutdated && dateStart;
  };

  return sortBy(events, compareFunction);
};

const convertDatetime = (datetime: string) => moment(datetime).format(DATE_FORMATS.DATE_TIME_FULL);

export const formatEventDateForSelect = (eventDate, dateFinish: boolean) => {
  let outdated;

  if (dateFinish) {
    outdated = isPasteDate(
      !eventDate.date_time_finish
        ? `${eventDate.date_finish} ${eventDate.time_finish}`
        : eventDate.date_time_finish,
    );
  } else {
    outdated = isPasteDate(
      !eventDate.date_time_start
        ? `${eventDate.date_start} ${eventDate.time_start}`
        : eventDate.date_time_start,
    );
  }

  return {
    value: eventDate.id,
    label: !eventDate.date_time_start
      ? `${convertDatetime(`${eventDate.date_start} ${eventDate.time_start}`)}`
      : eventDate.date_time_start,
    outdated,
  };
};

export const getPeriodicalChildrenData = (data, dateFinish) => {
  if (data.children || data) {
    const dates = sortChildrenEvents(data.children || data, dateFinish).map((eventDate) =>
      formatEventDateForSelect(eventDate, dateFinish),
    );

    return {
      eventDates: dates,
    };
  }

  return {};
};
